.loader-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0ABAB5;
    display: grid;
    place-items: center;
}

.loader {
    border: 16px solid #B6AED4;
    border-top: 16px solid #024A81;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

