svg.MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    padding: 0;
}

div.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
}

div.MuiListItem-root {
    padding: 0;
}

